body {
  color: black;
  background-color: #b3bdbe;
  margin: 10px;
  font-family: 'VT323', monospace !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.primary-nav {
  color: black;
  line-height: 15px;
  cursor: pointer;
  margin-top: 16px;
  margin-right: 10px;
  font-family: 'IBM Plex Mono', monospace;
  margin-left: 10px;
  position: relative;
  font-size: 18px;
  font-weight: bold;
  color: black;
}

.secondary-nav {
  color: black;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
  margin-top: 15px;
  margin-left: 10px;
}

.tertiary-nav a {
  color: black;
  margin-left: 1px;
  margin-right: 10px;
  font-size: 13px;
  margin-top: 10px;
  color: black;
  bottom: 15px;
  margin-left: 10px;
}

.tertiary-nav a:hover {
  color: black;
  background-color: #e3e624;
}


#text {
  margin-bottom: 5px;
  margin-left: 20px;
  font-size: 16px;
  text-decoration: underline;
}

#home-png {
  width: 200px;
  margin-top: 10px;
}


#contain {
  border-top-width: 5px black;
  border-top-style: solid;
  border-top-color: black;
}

#marmoset {
  display: block;
  margin-top: 10px;
  margin: auto;
  margin-left: 200px;
}

#video {
  margin-top: 10px;
  margin: auto;
  margin-left: 200px;
}

#nav-menu {
  margin-left: 6px;
  position: absolute;
  bottom: 15px;
  font-size: 12px;
}

a:link {
  color: black;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: black;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #e3e624;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: black;
  background-color: transparent;
  text-decoration: underline;
}

#appLayout {
  display: block;
  float: none;
}

#content {
  display: block;
  /* float: right; */
  width: 95%;
}
